<template>
 <div class="mt-8 flow-root">
  <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
   <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
     <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
       <tr>
        <!-- Render the th element only if header.name is not 'id' -->
        <template v-for="(header, index) in thead">
         <th
          v-if="header.name !== 'id'"
          :key="index"
          scope="col"
          :class="{ 'pl-4 pr-3 sm:pl-6': index === 1, 'px-3': index !== 1 }"
          class="py-3.5 text-left text-sm font-semibold text-gray-900"
         >
          {{ $t(header.name) }}
         </th>
        </template>
        <th v-if="hasPermission" scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
         <span class="sr-only">Edit</span>
        </th>
       </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
       <tr
        v-for="contact in tbody"
        :key="contact.id"
        class="hover:bg-gray-50 cursor-pointer"
        @click="$emit('actionDetail', contact.id)"
       >
        <!-- Render the td element only if the key is not 'id' -->

        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
         {{ contact.firstName ? contact.firstName : "-" }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ contact.lastName ? contact.lastName : "-" }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ contact.emailAddress ? contact.emailAddress : "-" }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ contact.mobilePhone ? contact.mobilePhone : "-" }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ contact.landlinePhone ? contact.landlinePhone : "-" }}
        </td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         <span
          :class="{
           'ring-1 ring-inset ring-red-200 text-red-700 bg-red-50': !contact.active,
           'ring-1 ring-inset ring-green-200 text-green-700 bg-green-50': contact.active,
          }"
          class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium"
         >
          {{ contact.active ? $t("active") : $t("nonactive") }}
         </span>
        </td>
        <!-- <td class="capitalize whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ $d(contact.created_at, "shortText") }}
        </td> -->
        <td class="capitalize whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ $d(contact.updated_at, "longText") }}
        </td>
        <td
         v-if="hasPermission"
         class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
        >
         <button
          type="button"
          @click.stop="$emit('actionDelete', contact)"
          class="hover:bg-red-50 hover:text-red-500 hover:ring-inset hover:ring-1 hover:ring-red-500 text-gray-700 relative -ml-px inline-flex items-center rounded-md bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20"
           fill="currentColor"
           class="w-5 h-5"
          >
           <path
            fill-rule="evenodd"
            d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
            clip-rule="evenodd"
           />
          </svg>
         </button>
        </td>
       </tr>
      </tbody>
     </table>
     <PaginationComponent
      :backend_pagination="true"
      :current_page="data.current_page"
      :first_page_url="data.first_page_url"
      :from="data.from"
      :last_page="data.last_page"
      :last_page_url="data.last_page_url"
      :links="data.links"
      :next_page_url="data.next_page_url"
      :per_page="data.per_page"
      :to="data.to"
      :total="data.total"
      :select_rows="selectRows"
      @action="$emit('action', $event)"
      @set-rows="$emit('set-rows', $event)"
     />
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import PaginationComponent from "./PaginationComponent.vue";
export default {
 name: "TableComponent",
 props: {
  data: {
   type: Array,
   required: true,
  },
  thead: {
   type: Array,
   required: true,
  },
  tbody: {
   type: Array,
   required: true,
  },
  hasPermission: {
   type: Boolean,
   required: true,
  },
 },
 emits: ["actionDetail"],
 components: {
  PaginationComponent,
 },
 data() {
  return {
   selectedRows: null,
   selectRows: [5, 10, 15, 20, 25, 30],
  };
 },
};
</script>

<style></style>
